import React, {Component} from 'react';
import '../css/tip.css';

class Tip extends Component {
    constructor(props) {
        super(props);
        this.state = {
            color: props.color,
            text: props.text
        }
    }

    render() {
        const show = this.props.show;
        return (
            <div className={'z-index-max'}
                 style={{position: 'relative'}}
                 onMouseLeave={this.props.action}>
                {show && (
                    <div className={'tip tipanim ' + this.state.color}>
                        <span className={'in-span ' + this.state.color}>{this.state.text}</span>
                        <span className={'rpt-arrow ' + this.state.color}/>
                    </div>
                )}
            </div>
        )
    }
}

export default Tip;


