import React, {Component} from 'react';
import {withRouter} from "react-router";
import {translate} from 'react-i18next';

import '../../css/faq.scss';

class Privacy extends Component {

    render() {
        const {t} = this.props;
        return (
            <div className={'center1500'}>
                <div className={'page-content padding-public'}>
                    <center>
                        <div className={'main-title'}>
                            <h1>{t('title')}</h1>
                        </div>

                            <div className="faq-container">
                                <div className={'q'}>{t('b1.title')}</div>
                                <div className={'a'}>{t('b1.text1')}</div>
                                <div className={'a'}>{t('b1.text2')}</div>
                                <div className={'a'}>{t('b1.text3')}</div>
                            </div>

                        <div className="faq-container">
                            <div className={'q'}>{t('b2.title')}</div>
                            <div className={'a'}>{t('b2.text1')}</div>
                            <div className={'a'}>{t('b2.text2')}</div>
                            <div className={'a'}>{t('b2.text3')}</div>
                            <div className={'a'}>{t('b2.text4')}</div>
                            <div className={'a'}>{t('b2.text5')}</div>
                            <div className={'a'}>{t('b2.text6')}</div>
                            <div className={'a'}>{t('b2.text7')}</div>
                            <div className={'a'}>{t('b2.text8')}</div>
                            <div className={'a'}>{t('b2.text9')}</div>
                            <div className={'a'}>{t('b2.text10')}</div>
                            <div className={'a'}>{t('b2.text11')}</div>
                            <div className={'a'}>{t('b2.text12')}</div>
                            <div className={'a'}>{t('b2.text13')}</div>
                        </div>

                        <div className="faq-container">
                            <div className={'q'}>{t('b3.title')}</div>
                            <div className={'a'}>{t('b3.text1')}</div>
                            <div className={'a'}>{t('b3.text2')}</div>
                            <div className={'a'}>{t('b3.text3')}</div>
                            <div className={'a'}>{t('b3.text4')}</div>
                        </div>

                        <div className="faq-container">
                            <div className={'q'}>{t('b4.title')}</div>
                            <div className={'a'}>{t('b4.text1')}</div>
                            <div className={'a'}>{t('b4.text2')}</div>
                            <div className={'a'}>{t('b4.text3')}</div>
                        </div>

                        <div className="faq-container">
                            <div className={'q'}>{t('b5.title')}</div>
                            <div className={'a'}>{t('b5.text1')}</div>
                            <div className={'a'}>{t('b5.text2')}</div>
                        </div>

                        <div className="faq-container">
                            <div className={'q'}>{t('b6.title')}</div>
                            <div className={'a'}>{t('b6.text1')}</div>
                            <div className={'a'}>{t('b6.text2')}</div>
                            <div className={'a'}>{t('b6.text3')}</div>
                            <div className={'a'}>{t('b6.text4')}</div>
                            <div className={'a'}>{t('b6.text5')}</div>
                        </div>

                        <div className="faq-container">
                            <div className={'q'}>{t('b7.title')}</div>
                            <div className={'a'}>{t('b7.text1')}</div>
                            <div className={'a'}>{t('b7.text2')}</div>
                        </div>

                        <div className="faq-container">
                            <div className={'q'}>{t('b8.title')}</div>
                            <div className={'a'}>{t('b8.text1')}</div>
                            <div className={'a'}>{t('b8.text2')}</div>
                            <div className={'a'}>{t('b8.text3')}</div>

                        </div>


                    </center>
                </div>
            </div>
        )
    }
}

export default withRouter(translate('privacy')(Privacy));
