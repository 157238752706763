import React, {Component} from 'react';
import Footer from "./PublicFooter.js";
import Header from "./PublicHeader.js";
import i18n from "../i18n";
import langs from './../i18n/list'
import {translate} from 'react-i18next';
import {withRouter} from "react-router";

import './../../src/css/public.scss';

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {width: 0, height: 0, menu: false, mobile: false};
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        let mobile = false;
        if (window.innerWidth <= 720) {
            mobile = true;
        }
        this.setState({width: window.innerWidth, height: window.innerHeight, mobile: mobile});
    }

    render() {

        let lang = this.props.match.params.lang;

        let err = true;
        for (let i = 0; i < langs.length; i++) {
            let item = langs[i];
            if (item.id === lang) {
                err = false;
            }
        }

        if (err || lang === 'undefined') {
            lang = 'en';
            if (this.props.link === "") {
                i18n.changeLanguage(lang).then(r => {

                });
                window.location.href = '/' + lang + this.props.link;
                return
            }
        }

        if (i18n.language !== lang) {
            i18n.changeLanguage(lang).then(r => {

            });
        }
        const MyComponent = this.props.comp;
        return (
            <div className={'main-container'}>
                <Header page={this.props.page} {...this.props} mobile={this.state.mobile}/>
                {this.props.page_type !== "auth" && <MyComponent {...this.props} mobile={this.state.mobile}/>}
                <Footer {...this.props}/>
            </div>
        )
    }
}

export default withRouter(translate('main')(App));

