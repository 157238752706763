import React, {Component} from 'react';
import {withRouter} from "react-router";
import {translate} from 'react-i18next';

import '../../css/types.scss';

class Index extends Component {

    render() {
        const {t} = this.props;
        return (
            <div className={'center1500'}>
                <div className={'page-content padding-public'}>

                    <center>
                        <div className={'main-title'}>
                            <h1>{t('title1')}</h1>
                            {t('title2')}
                        </div>

                        <br/><br/>
                        <div className={'content-container revers'}>
                            <div className={'content-block'}>
                                <div>
                                    <h2 className={'title'}>{t('type1.title')}</h2>
                                    <p>{t('type1.text1')}</p>
                                    <p>{t('type1.text2')}</p>
                                    <ul>
                                        <li>{t('type1.text3')}</li>
                                        <li>{t('type1.text4')}</li>
                                        <li>{t('type1.text5')}</li>
                                        <li>{t('type1.text6')}</li>
                                    </ul>
                                </div>
                            </div>
                            <div className={'content-block pic1'}/>
                        </div>
                        <div className={'content-container'}>
                            <div className={'content-block pic2'}/>
                            <div className={'content-block'}>
                                <div>
                                    <h2 className={'title'}>{t('type2.title')}</h2>
                                    <p>{t('type2.text1')}</p>
                                    <p>{t('type2.text2')}</p>
                                </div>
                            </div>
                        </div>
                        <div className={'content-container revers'}>
                            <div className={'content-block'}>
                                <div>
                                    <h2 className={'title'}>{t('type3.title')}</h2>
                                    <p>{t('type3.text1')}</p>
                                    <p>{t('type3.text2')}</p>
                                </div>
                            </div>
                            <div className={'content-block pic3'}/>
                        </div>
                        <div className={'content-container'}>
                            <div className={'content-block pic4'}/>
                            <div className={'content-block'}>
                                <div>
                                    <h2 className={'title'}>{t('type4.title')}</h2>
                                    <p>{t('type4.text1')}</p>
                                    <br/>
                                    <h2 className={'title'}>{t('type5.title')}</h2>
                                    <p>{t('type5.text1')}</p>

                                </div>
                            </div>
                        </div>
                        <div className={'content-container revers'}>
                            <div className={'content-block'}>
                                <div>
                                    <h2 className={'title'}>{t('type6.title')}</h2>
                                    <p>{t('type6.text1')}</p>
                                    <br/>
                                    <h2 className={'title'}>{t('type7.title')}</h2>
                                    <p>{t('type7.text1')}</p>
                                </div>
                            </div>
                            <div className={'content-block pic6'}/>
                        </div>
                        <div className={'content-container'}>
                            <div className={'content-block pic8'}/>
                            <div className={'content-block'}>
                                <div>
                                    <h2 className={'title'}>{t('type8.title')}</h2>
                                    <p>{t('type8.text1')}</p>
                                    <p>{t('type8.text2')}</p>
                                </div>
                            </div>
                        </div>
                        <div className={'content-container'}>
                            <div className={'content-block'}>
                                <div>
                                    <h2 className={'title'}>{t('type9.title')}</h2>
                                    <p>{t('type9.text1')}</p>
                                </div>
                            </div>
                            <div className={'content-block pic9'}/>
                        </div>
                    </center>
                </div>
            </div>
        )
    }
}

export default withRouter(translate('types')(Index));
