import React, {Component} from 'react';
import {withRouter} from "react-router";
import {translate} from 'react-i18next';
import Moment from "react-moment";
import NewsRu from "./../../i18n/locales/news.ru.json";
import Art from "./Art.js";

import '../../css/news.scss';
import {NavLink} from "react-router-dom";

class NewsItem extends Component {

    press_news = (text) => {
        if (text === "undefined") {
            return
        }
        text = text.replace(/\\</gi, '');
        text = text.replace(/\\>/gi, '');
        text = text.replace(
            /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi,
            '<a target="_new" class="url" href="$1">$1</a>'
        );
        text = text.replace(/\n/gi, '<br/>');
        return text;
    };

    render() {
        const {t, i18n} = this.props;
        let news = NewsRu.art[this.props.match.params.id - 1];
        return (
            <div className={'center1500'}>
                <div className={'page-content padding-public'}>

                    <center>
                        <div className={'news-item-container'}>

                            <div className={'path'}>
                                <NavLink key={"all"} to={"/" + i18n.language + '/news'}
                                         activeClassName="active">
                                    {t('path-news')}
                                </NavLink>
                            </div>

                            <font className={'date'}>
                                <Moment format={"DD/MM/YYYY"}>{news.date}</Moment>
                            </font>

                            <h1>{news.subject}</h1>
                            <br/>
                            <div className={'news-text'}
                                 dangerouslySetInnerHTML={{__html: this.press_news(news.text)}}/>

                            <br/><br/>

                            <div className={'news-pre-container item'}>
                                <Art {...this.props}/>
                            </div>

                        </div>
                    </center>
                </div>
            </div>
        )
    }
}

export default withRouter(translate('news')(NewsItem));
