import React, {Component} from 'react';
import {withRouter} from "react-router";
import {translate} from 'react-i18next';

import '../../css/start.scss';

class Home extends Component {

    render() {
        const {t, i18n} = this.props;
        return (
            <div className={'center1500'}>
                <div className={'page-content padding-public'}>
                    <center>
                        <div className={'main-title'}>
                            <h1>{t('title1')}</h1>
                            <br/>

                        </div>
                        <div className="start-container">

                            <div className={'warning-container'}>
                                <div className={'ico'}/>
                                <div className={'text'}>
                                    {t('title2')}<br/><br/>
                                    {t('title3')}
                                </div>
                            </div>

                        </div>
                        <br/><br/>

                        <div className={'main-title'}>
                            <h1>{t('title4')}</h1>
                        </div>

                        <div className="start-container">

                            <div className={'step-container'}>
                                <div className={'dd'}>
                                    <div className={'img step1'}>{t('steps.step1')}</div>
                                    <div className={'img step2'}>{t('steps.step2')}</div>
                                    <div className={'img step3'}>{t('steps.step3')}</div>
                                </div>
                                <div className={'dd'}>
                                    <div className={'img step4'}>{t('steps.step4')}</div>
                                    <div className={'img step5'}>{t('steps.step5')}</div>
                                    <div className={'img step6'}>{t('steps.step6')}</div>
                                </div>
                            </div>

                            <div className={'text-container'}>
                                {t('text1')}
                                <br/>
                            </div>

                            <br/>
                            <div className={'button'}
                                 onClick={() => this.props.history.push('/' + i18n.language + '/contact')}>{t('order_visa')}
                            </div>

                            <br/><br/>

                        </div>
                    </center>
                </div>
            </div>
        )
    }
}

export default withRouter(translate('start')(Home));
