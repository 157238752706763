import React, {Component} from 'react';
import {withRouter} from "react-router";
import {translate} from 'react-i18next';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import '../../css/contact.scss';
import {axiosCatch, axiosinstance} from "../../modules/axios";
import Tip from "../../modules/tip";
import PshButton2 from "../../modules/pshButtons2";

//import ReCAPTCHA from "react-google-recaptcha";
import HCaptcha from '@hcaptcha/react-hcaptcha';
import * as EmailValidator from "email-validator";

class Index extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      tooltip: {
        name: false,
        email: false,
        phone: false,
        message: false,
        captcha: false,
      },
      form: {
        name: "",
        phone: "",
        email: "",
        message: "",
        captcha: false,
        code: "123"
      },
      loading: false
    }
  }

  showTooltip = (target, bool) => {
    let tooltip = this.state.tooltip;
    tooltip[target] = bool;
    this.setState({
      tooltip: tooltip
    });
  };

  handleOnChange = (name, event) => {
    let form = this.state.form;
    form[name] = event.target.value;
    this.setState({
      form: form
    });
    if (name === 'name') {
      if (event.target.value.length < 3 || event.target.value.length > 40) {
        this.showTooltip('name', false);
      }
    }
    if (name === 'message') {
      if (event.target.value.length < 10 || event.target.value.length > 1000) {
        this.showTooltip('message', false);
      }
    }
    if (name === 'email') {
      if (EmailValidator.validate(event.target.value)) {
        this.showTooltip('email', false);
      }
    }
  };

  submitForm() {

    let error = false;

    if (this.state.form.name < 3 || this.state.form.name > 40) {
      this.showTooltip('name', true);
      error = true;
    }

    if (this.state.form.message < 10 || this.state.form.message > 1000) {
      this.showTooltip('message', true);
      error = true;
    }

    if (!EmailValidator.validate(this.state.form.email)) {
      this.showTooltip("email", true);
      error = true;
    }

    if (error) {
      return
    }

    this.setState({
      loading: true,
    });


    axiosinstance({
      method: 'post',
      url: '//' + process.env.REACT_APP_BACKEND_URL + "/form/message",
      data: this.state.form
    })
      .then((response) => {
        let ans = response.data;
        if (ans.success === true) {
          console.log(ans);
          const {t} = this.props;
          NotificationManager.success(t('form.notification.success.text'), t('form.notification.success.title'));
          this.setState({
            form: {
              name: "",
              phone: "",
              email: "",
              message: "",
              captcha: false,
              code: "123"
            }
          })
        }
        this.setState({
          loading: false
        })
      }).catch((args) => {
      axiosCatch(args, () => this.loading(), this.props);
    });

  }

  loading(bool) {
    this.setState(
      {
        loading: bool
      }
    )
  }

  handleVerificationSuccess(token, ekey) {
    //alert(token + " / " + ekey);
    if (token === null || token === undefined) {
      return
    }
    let form = this.state.form;
    form['code'] = token;
    form['captcha'] = true;
    this.setState({
      form: form
    });
  }

  render() {
    const {t} = this.props;
    return (
      <div className={'center1500'}>
        <div className={'page-content padding-public'}>
          <center>
            <div className={'main-title'}><h1>{t('title1')}</h1>
              {t('title2')}
            </div>
            <div className="contact-container">

              <div className={'contact-items'}>
                <div className={'item phone'}>
                  {t('call')}
                  <br/>
                  <font className={'info'}>{t('call_data')}</font>
                </div>
                <div className={'item chat hide-mobile'}>
                  {t('write')}
                </div>
                <div className={'item chat'}>
                  {t('telegram')}
                  <br/>
                  <a href={"https://t.me/royalvisa"} target={"_new"} className={'telegram'}><font className={'info'}>{t('message_data')}</font></a>
                </div>
              </div>
            </div>
            <br/><br/>
            <div className={'main-title'}>
              <h1>{t('title3')}</h1>
              {t('title4')}
            </div>
            <div className="contact-container">
              <div className="form-container">
                <div className={'contact-box'}>
                  <form>
                    <label>{t('form.name')}</label>
                    <Tip
                      text={t('error.name-incorrect')}
                      show={this.state.tooltip.name}
                      action={() => this.showTooltip('name', false)}/>

                    <input className={"form-control-lg"}
                           onChange={this.handleOnChange.bind(this, 'name')}
                           type="name"
                           value={this.state.form.name}
                           placeholder={t('form.name')}/>


                    <label>{t('form.email')}</label>
                    <Tip
                      text={t('error.email-incorrect')}
                      show={this.state.tooltip.email}
                      action={() => this.showTooltip('email', false)}/>

                    <input className={"form-control-lg"}
                           onChange={this.handleOnChange.bind(this, 'email')}
                           type="email"
                           value={this.state.form.email}
                           placeholder={t('form.email')}/>


                    <label>{t('form.phone')}</label>
                    <Tip
                      text={t('error.phone-incorrect')}
                      show={this.state.tooltip.phone}
                      action={() => this.showTooltip('phone', false)}/>

                    <input className={"form-control-lg"}
                           onChange={this.handleOnChange.bind(this, 'phone')}
                           type="phone"
                           value={this.state.form.phone}
                           placeholder={t('form.phone')}/>


                    <label>{t('form.message')}</label>
                    <Tip
                      text={t('error.message-incorrect')}
                      show={this.state.tooltip.message}
                      action={() => this.showTooltip('message', false)}/>

                    <textarea value={this.state.form.message}
                              onChange={this.handleOnChange.bind(this, 'message')}
                              className={'textarea-project-edit'}>
                                        </textarea>

                    {/*<ReCAPTCHA*/}
                    {/*    sitekey="6LeNu_sUAAAAAEQe1iECs_Xdj9SYfdeRRLBv6r7i"*/}
                    {/*    onChange={this.onChange}*/}
                    {/*/>*/}

                    <HCaptcha
                      sitekey="4d0c61bd-b1b4-4d67-b19b-9c768d3766b9"
                      onVerify={(token, ekey) => this.handleVerificationSuccess(token, ekey)}
                    />

                    {this.state.form.code.length > 0 ?
                      <PshButton2 icon={'send'} text={t('send')}
                                  loading={this.state.loading}
                                  onClick={() => this.submitForm()}/> :
                      <PshButton2 icon={'send'} disabled text={t('send')}
                                  loading={this.state.loading}/>
                    }

                  </form>
                  <NotificationContainer/>
                </div>
                <div className={'contact-img'}/>
              </div>
            </div>
          </center>
        </div>
      </div>
    )
  }
}

export default withRouter(translate('contact')(Index));
