import React, {Component} from 'react';
import Loading from "./loading2";

class PshButton extends Component {
    render() {
        if (this.props.paddingRight) {
            return (
                <div style={{paddingRight: 20}}>
                    <Internal {...this.props}/>
                </div>
            )
        }
        if (this.props.paddingLeft) {
            return (
                <div style={{paddingLeft: 20}}>
                    <Internal {...this.props}/>
                </div>
            )
        }
        return (
            <div style={{paddingLeft: 0}}>
                <Internal {...this.props}/>
            </div>
        )
    }
}

export default PshButton;

class Internal extends Component {
    render() {

        return (
            <>
                {this.props.disabled ?
                    (
                        <div className={this.props.small ? 'button small' : 'button'} style={{opacity: 0.3, cursor: 'default'}}>
                            <>{this.props.text}</>
                        </div>
                    ) : (
                        <div className={this.props.small ? 'button small' : 'button'}
                             onClick={this.props.onClick}>
                            {this.props.loading ? (
                                <>
                                    <Loading size={this.props.small ? 'xsmall' : 'small'} color={'white'}/>
                                    <div style={{opacity: 0}}> {this.props.text}</div>
                                </>
                            ) : (
                                <>{this.props.text}</>
                            )}
                        </div>

                    )}
            </>
        )
    }
}
