import React, {Component} from 'react';
import {withRouter} from "react-router";
import {translate} from 'react-i18next';

import '../../css/home.scss';

class Home extends Component {

    render() {
        const {t, i18n} = this.props;
        return (
            <div className={'center1500'}>
                <div className={'page-content padding-public'}>
                    <center>
                        <div className={'main-title'}>
                            {t('title1')}<br/>
                            {t('title2')}
                        </div>
                        <div className="home-container">
                            <div className={'content-block'}>
                                <div className={'content-block-image b1'} onClick={() => this.props.history.push("/" + i18n.language + "/types")}/>
                                <br/>
                                <div className={'title'}>{t('main-block-b1-title')}</div>
                                <div className={'text'}>{t('main-block-b1-text')}</div>
                            </div>
                            <div className={'content-block'} onClick={() => this.props.history.push("/" + i18n.language + "/terms")}>
                                <div className={'content-block-image b2'}/>
                                <br/>
                                <div className={'title'}>{t('main-block-b2-title')}</div>
                                <div className={'text'}>{t('main-block-b2-text')}</div>
                            </div>
                            <div className={'content-block'} onClick={() => this.props.history.push("/" + i18n.language + "/faq")}>
                                <div className={'content-block-image b3'}/>
                                <br/>
                                <div className={'title'}>{t('main-block-b3-title')}</div>
                                <div className={'text'}>{t('main-block-b3-text')}</div>
                            </div>
                            <div className={'content-block'}>
                                <div className={'content-block-image b4'}/>
                                <br/>
                                <div className={'title'}>{t('main-block-b4-title')}</div>
                                <div className={'text'}>{t('main-block-b4-text')}</div>
                            </div>
                            <div className={'content-block'}>
                                <div className={'content-block-image b5'}/>
                                <br/>
                                <div className={'title'}>{t('main-block-b5-title')}</div>
                                <div className={'text'}>{t('main-block-b5-text')}</div>
                            </div>
                            <div className={'content-block'}>
                                <div className={'content-block-image b6'}/>
                                <br/>
                                <div className={'title'}>{t('main-block-b6-title')}</div>
                                <div className={'text'}>{t('main-block-b6-text')}</div>
                            </div>
                        </div>
                    </center>
                </div>
            </div>
        )
    }
}

export default withRouter(translate('home')(Home));
