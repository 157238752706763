import Loader from "react-loader-spinner";
import React, {Component} from "react";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

class View extends Component {
    render() {
        let size = 40;
        if (this.props.size === 'small') {
            size = 20;
        }
        if (this.props.size === 'xsmall') {
            size = 12;
        }
        let color = '#4c86b1';
        if (this.props.color === 'white') {
            color = '#ffffff';
        }
        return (

            <Loader
                type="Oval"
                color={color}
                height={size}
                width={size}
                timeout={30000}/>
        )
    }
}
export default (View);
