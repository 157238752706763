import React, {Component} from 'react';
import {translate} from 'react-i18next';
import {withRouter} from 'react-router'
import langs from './../i18n/list'
import appRoutes from "../routes";
import {NavLink} from "react-router-dom";

class Header extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      showLangBlock: false,
      langs: langs,
      langMap: null,
      lang: "",
    }
  }

  detect = () => {
    let {i18n} = this.props;
    let getLang = i18n.language;

    //let getLang = localStorage.getItem('i18nextLng');

    let err = true;
    for (let i = 0; i < this.state.langs.length; i++) {
      let item = this.state.langs[i];
      if (item.id === getLang) {
        err = false;
      }
    }

    if (!err) {
      //i18n.changeLanguage(getLang);
      this.setState({
        lang: getLang
      });

    } else {
      console.log("error detect");
      //i18n.changeLanguage('en');
      this.setState({
        lang: 'en'
      });

    }

  };

  componentDidMount() {
    this.detect();
    let langMap = new Map();
    let langs = this.state.langs;
    for (let i = 0; i < langs.length; i++) {
      langMap.set(langs[i].id, langs[i])
    }

    this.setState({
      showLangBlock: false,
      langMap: langMap,
    });
  };

  render() {
    let MyComponent = this.props.comp;
    if (this.props.name === "home") {
      return (
        <header className={'header-public'}>
          <HeaderContentHome {...this.props}/>
          <Menu {...this.props}/>
        </header>
      );
    }
    if (this.props.page_type === "page") {
      return (
        <header className={'header-page'}>
          <HeaderContent {...this.props}/>
          <Menu {...this.props} />
        </header>
      )
    }
    if (this.props.page_type === "auth") {
      return (
        <header className={'header-auth'}>
          <Menu {...this.props} />
          <MyComponent {...this.props}/>
        </header>
      )
    }
    return null
  }
}

export default withRouter(translate(['header', 'contact'])(Header));


class HeaderContentHome extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      visible1: false
    }
  }

  render() {
    const {t} = this.props;
    return (
      <div className={'top-container-home center'}>
        <Top {...this.props}/>
        <div className={'header-home-logo'}/>
        <div className={"title"}>{t('title1')}</div>
        <div className={"title2"}>{t('title2')} {t('title3')}</div>
      </div>
    )
  }
}

class HeaderContent extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      visible1: false
    }
  }

  render() {
    const {t} = this.props;
    return (
      <div className={'top-container width'}>
        <div className={'center'}>
          <div className={'in-container'}>
            <Top {...this.props}/>
            <div className={'header-home-logo'}/>
            <div className={"title"}>{t('title1')}</div>
          </div>
        </div>
      </div>
    )
  }
}

class Top extends Component {
  render() {
    const {t} = this.props;
    return (
      <div className={'top'}>
        <div className={'contact-container'}>
          <div><b>{t('contact:call')}:</b> {t('contact:call_data')}</div>
          {(this.props.name === 'home' || !this.props.mobile) && <>
            <div><b>{t('contact:telegram')}:</b>
              <a href={"https://t.me/royalvisa"} target={"_new"} className={'telegram'}>{t('contact:message_data')}</a>
            </div>
            <div><b>{t('contact:email')}:</b> {t('contact:email_data')}</div>
          </>
          }
        </div>
      </div>
    )
  }
}


class Menu extends Component {

  constructor(props) {
    super(props);
    this.state = {mobileMenu: false};
  }

  handle = () => {
    this.setState(
      {
        menu: !this.state.menu
      }
    )
  }

  changeLinkIDvariable(link) {
    if (link === undefined) {
      return "";
    } else {
      return link.replace(":id", this.props.match.params.id);
    }
  }

  switchMobileMenu = () => {
    this.setState({
      mobileMenu: !this.state.mobileMenu
    })
  }

  render() {
    let i18n = this.props.i18n;
    const {
      t,
    } = this.props;

    // let session = localStorage.getItem('session');
    //
    return (
      <div className={'head'}>
        <div className={'flex-row width'}>
          <Logo/>
          <div className={'width'}>
            <div className={'button-menu flex'} onClick={this.handle}/>
            <>
              {(this.state.mobileMenu || this.props.mobile) && (
                <>
                  <div className={'mobile-menu-icon'} onClick={this.switchMobileMenu}>
                    Меню
                  </div>
                  {this.state.mobileMenu &&
                  <div className={'menu-display-mobile'}>
                    {
                      appRoutes.map((prop, key) => {
                        if (!prop.redirect) {
                          if (prop.public_menu) {
                            return (
                              <NavLink key={key} to={"/" + i18n.language + prop.link}
                                       activeClassName="active">
                                {t('menu.' + prop.name)}
                              </NavLink>
                            );
                          }
                        }
                        return null;
                      })
                    }
                  </div>
                  }
                </>
              )}
            </>
            <>
              {(!this.props.mobile) && (
                <>


                  {/*{session !== null && (*/}
                  {/*    <div className={'dashboard-div'}*/}
                  {/*         onClick={() => this.props.history.push('/dashboard')}>*/}
                  {/*        {t('header.menu.return-to-dashboard')}*/}
                  {/*    </div>*/}
                  {/*)}*/}


                  {/*{langs.map(item =>*/}
                  {/*    <a className={item.flag.toLowerCase() === i18n.language.toLowerCase() ? ' active ' : ' '}*/}
                  {/*       href={'/' + item.id + this.changeLinkIDvariable(this.props.link)}*/}
                  {/*        //onClick={() => this.changeLang(item.id)}*/}
                  {/*       key={item.id}>*/}
                  {/*        {item.flag}*/}
                  {/*    </a>*/}
                  {/*)}*/}

                  <div className={'menu-display'}>
                    {
                      appRoutes.map((prop, key) => {
                        if (!prop.redirect) {
                          if (prop.public_menu) {
                            return (
                              <NavLink key={key} to={"/" + i18n.language + prop.link}
                                       activeClassName="active">
                                {t('menu.' + prop.name)}
                              </NavLink>
                            );
                          }
                        }
                        return null;
                      })
                    }
                    {/*<NavLink key={"start"} to={"/signup"} className="" style={{marginRight: -20}}>*/}
                    {/*    {t('header.menu.register')}*/}
                    {/*</NavLink>*/}
                  </div>
                  <hr/>
                </>
              )}
            </>
          </div>
        </div>

      </div>
    )
  }
}

class Logo extends Component {
  render() {
    return (
      // eslint-disable-next-line jsx-a11y/anchor-has-content
      <a className={'logo'} href={"/"} target={"_new"}/>
    );
  }
}
