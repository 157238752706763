
const langs = [
    {
        id: "ru",
        name: "Русский",
        flag: "RU"
    }
];

export default langs;
