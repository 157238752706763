import NewsRu from "../../i18n/locales/news.ru.json";
import Moment from "react-moment";
import React, {Component} from "react";
import {withRouter} from "react-router";
import {translate} from "react-i18next";

class Art extends Component {

    render() {
        const {t, i18n} = this.props;
        NewsRu.art.sort(function(a, b){ return a.date - b.date });
        return (
            <div className={'news'}>
                <h1>{t('title2')}</h1>
                <div className={'news-separate'}/>
                {NewsRu.art.map((item, index) =>
                    <div className={'news-container'} key={index}>
                        <div className={'date'}><Moment
                            format={'DD/MM/YYYY'}>{item.date}</Moment>
                        </div>
                        <div className={'text'}>
                            <font className={'title'}>{item.subject}</font>
                            <font className={'read'}
                                  onClick={() => this.props.history.push("/" + i18n.language + "/article/" + item.id)}>{t('read')}</font>
                        </div>
                    </div>
                )}
            </div>
        )
    }
}

export default withRouter(translate('news')(Art));
