//import NewsRu from "../../i18n/locales/news.ru.json";
import Moment from "react-moment";
import React, {Component} from "react";
import {withRouter} from "react-router";
import {translate} from "react-i18next";
import {NEWS} from "../../api/index";

class News extends Component {
  constructor() {
    super();
    this.state = {
      news: [],
      loading: true
    }
  }

  componentDidMount() {
    NEWS
      .then(news => this.setState({news: news.news, loading: false}))
      .catch(message => {
        this.setState({
          loading: false
        })
      })
  }

  render() {
    const {t} = this.props;
    const {news} = this.state;
    // this.state.news.sort(function (b, a) {
    //   return a.date - b.date
    // });

    return (
      <div className={'news'}>
        <h1>{t('title1')}</h1>
        <div className={'news-separate'}/>
        {news && (
          news.map((item, index) =>
              <div className={'news-container'} key={index}>
                <div className={'date'}><Moment
                  format={'DD/MM/YYYY'}>{item.date*1000}</Moment>
                </div>
                <div className={'text'}>
                  <font className={'title'}>{item.text.split('\n').map((line, i) => (
                    <span key={i}>
                      {line}
                      <br/>
                    </span>
                  ))
                  }</font>
                  {/*<font className={'read'}*/}
                  {/*      onClick={() => this.props.history.push("/" + i18n.language + "/news/" + item.id)}>{t('read')}</font>*/}
                </div>
              </div>
          ))
        }

      </div>
    )
  }
}

export default withRouter(translate('news')(News));
