import {notify} from 'react-notify-toast';

    let axios = require('axios');

export const axiosinstance = axios.create({
    headers: {
        'Content-Type': 'application/json'
    },
    withCredentials: false,
    timeout: 15000,
    mode: 'no-cors',
    crossdomain: true,
});

export function axiosCatch(args, action, props) {
    const t = props.t;
    console.log(JSON.stringify(args));

    if (!args.response) {
        action(false);
        notify.show(t('error.unknown-error'), "custom", 5000, {background: '#FF4F3F', text: "#FFFFFF"});
    } else {
        let ans = args.response.data;
        if (ans.success === false) {
            action(false);
            notify.show(t('error.error') + ' ' + t('code.' + ans.code), "custom", 5000, {background: '#FF4F3F', text: "#FFFFFF"});
        }
        if (ans.code === "3" || ans.code === "4") {
            props.history.push("/login");
        }
    }
    console.log(args);
}
