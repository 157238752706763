import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import ru from './locales/ru.json';

i18n
    .use(LanguageDetector)
    .init({
        fallbackLng: 'ru',
        load: 'languageOnly',
        whitelist: [
            'ru',
        ],
        initImmediate: true,
        resources: {
            ru,
        },
        react: {
            wait: true,
        },
        interpolation: {
            escapeValue: true
        }
    }).then(r => {

});

export default i18n;
