import React, {Component} from 'react';
import {withRouter} from "react-router";
import {translate} from 'react-i18next';
import FaqRu from "./../../i18n/locales/faq.ru.json";

import '../../css/faq.scss';

class Faq extends Component {

    render() {
        const {t} = this.props;
        return (
            <div className={'center1500'}>
                <div className={'page-content padding-public'}>
                    <center>
                        <div className={'main-title'}>
                            <h1>{t('title1')}</h1>
                        </div>

                        {FaqRu.faq.map((item, index) =>
                            <div className="faq-container" key={index}>
                                <div className={'q'}>{item.q}</div>
                                <div className={'a'}>{item.a}</div>
                            </div>
                        )}

                    </center>
                </div>
            </div>
        )
    }
}

export default withRouter(translate('faq')(Faq));
