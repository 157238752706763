import React, {Component} from 'react';
import {withRouter} from "react-router";
import {translate} from 'react-i18next';
import NewsRu from "./../../i18n/locales/news.ru.json";
import News from "./News.js";

import '../../css/news.scss';

class Index extends Component {

  render() {
    const {i18n, t} = this.props;

    NewsRu.art.sort(function (b, a) {
      return a.date - b.date
    });

    return (
      <div className={'center1500'}>
        <div className={'page-content padding-public'}>

          <center>
            <div className={'flex-news'}>

              <div className={'news-pre-container index'}><News {...this.props}/></div>
              <div className={'articles'}>
                <h1>{t('title2')}</h1>
                <br/>
                {NewsRu.art.map((item, index) =>
                  <div key={index}>
                    {this.props.mobile ? (
                      <div className={'art-pre-container'}>
                        <div className={'art-container'}>
                          <div className={'text'}>
                            <font className={'title'}>{item.subject}</font>
                            {item.short}
                          </div>
                          <div className={'left'}>
                            <font className={'read'}
                                  onClick={() => this.props.history.push("/" + i18n.language + "/article/" + item.id)}>{t('read')}</font>
                          </div>

                        </div>
                      </div>
                    ) : (
                      <div className={'art-pre-container'}
                           onClick={() => this.props.history.push("/" + i18n.language + "/article/" + item.id)}>
                        <div className={'art-container'}>
                          <div className={'img'}/>
                          <div className={'text'}>
                            <font className={'title'}>{item.subject}</font>
                            {item.short}
                          </div>
                        </div>
                        <div className={'art-container-shadow'}>
                          <img alt={''} className={'img'}
                               src={'/img/art' + item.id + '.jpg'}/>
                          <div className={'text'}/>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </center>


        </div>
      </div>
    )
  }
}

export default withRouter(translate('news')(Index));
