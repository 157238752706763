import {API_URL} from "./config";

function request(query) {
  return new Promise((resolve, reject) => {
    const requestOptions = {
      method: 'GET',
      headers: {'Content-Type': 'application/json'},
    };
    fetch(API_URL + query, requestOptions)
      .then(async response => {
        const data = await response.json();
        if (!response.ok) {
          console.error('There was an error!', response);
          //const error = (data && data.message) || response.status;
          //reject(response.status);
        }
        //console.log('ok' + query + " / " + JSON.stringify(data));
        //alert(JSON.stringify(data))
        resolve(data)
      })
      .catch(error => {
        console.error('There was an error!', error);
        //reject(error);
      });
  })
}

export const NEWS = request("/news");

