import React, {Component} from 'react';
import {Switch, Route} from 'react-router-dom'
import ScrollToTop from './modules/scrolltotop'
import {translate} from 'react-i18next';
import appRoutes from './routes.js';
import Notifications from 'react-notify-toast';
import {withRouter} from "react-router";
import PageRedirect from "./redirect";
import {Helmet} from "react-helmet";

require('dotenv').config();

//@observer
class App extends Component {

  render() {
    const {i18n} = this.props;
    let lang = i18n.language.split("-")[0];
    return (
        <div className={'after_root'}>
          <Notifications/>
          <ScrollToTop>
            {/*<Socket {...this.props}/>*/}
            <Switch>
              {
                appRoutes.map((prop, key) => {
                  if (prop.redirect)
                    return (
                        <PageRoute {...this.props}
                                   exact
                                   comp={prop.comp}
                                   name={prop.name}
                                   path={prop.path}
                                   component={(props) => <PageRedirect {...this.props} {...props}
                                                                       path={"/" + lang + prop.path}/>}
                                   key={key}
                        />
                    );
                  return (
                      <PageRoute {...this.props}
                                 exact
                                 name={prop.name}
                                 path={prop.path}
                                 key={key}
                                 component={(props) => <prop.container
                                     {...props}
                                     {...this.props}
                                     comp={prop.comp}
                                     name={prop.name}
                                     link={prop.link}
                                     page_type={prop.type}
                                 />
                                 }
                                 comp={prop.comp}
                      />
                  );
                })
              }
            </Switch>
          </ScrollToTop>
        </div>
    );
  }
}

class PageRoute extends Route {


  // componentDidMount() {
  //     document.title = "Stella - " + this.props.title
  // }
  //
  // componentDidUpdate() {
  //     document.title = "Stella - " + this.props.title
  // }

  render() {
    const {t} = this.props;
    let title = t(this.props.name + '.title');
    const description = t(this.props.name + '.description');
    return (
        <>
          <Helmet>
            <title>{ `${ title } / Royal Visa` }</title>
            <meta name="description" content={description}/>
          </Helmet>
          <Route {...this.props}/>
        </>
    )
  }
}

export default withRouter(translate('head')(App));
