import AppPublic from "./containers/PublicApp.js";
import Home from "./pages/home/Index";
import Types from './pages/types/Index';
import News from './pages/news/Index';
import NewsItem from "./pages/news/NewsItem";
import ArticleItem from "./pages/news/ArtItem";
import Terms from './pages/terms/Index';
import Faq from './pages/faq/Index';
import Contact from './pages/contact/Index';
import Start from './pages/start/Index';
import Price from './pages/price/Index';
import Privacy from './pages/privacy/Index';
import Logo from './pages/logo/Index';

const appRoutes = [

    //lang redirect
    {path: "/home", to: "/home", redirect: true},
    {path: "/types", to: "/types", redirect: true},
    {path: "/news", to: "/news", redirect: true},
    {path: "/news/:id", to: "/news/:id", redirect: true},
    {path: "/article/:id", to: "/article/:id", redirect: true},
    {path: "/price", to: "/price", redirect: true},
    {path: "/about", to: "/about", redirect: true},
    {path: "/terms", to: "/terms", redirect: true},
    {path: "/faq", to: "/faq", redirect: true},
    {path: "/contact", to: "/contact", redirect: true},
    {path: "/start", to: "/start", redirect: true},
    {path: "/price", to: "/price", redirect: true},
    {path: "/privacy", to: "/privacy", redirect: true},
    {path: "/logo", to: "/logo", redirect: true},

    //no redirect pages
    {
        path: "/:lang",
        type: "home",
        link: "/home",
        name: "home",
        container: AppPublic,
        comp: Home,
    },
    {
        path: "/:lang/home",
        type: "home",
        link: "/home",
        name: "home",
        container: AppPublic,
        comp: Home,
        public_menu: true
    },
    {
        path: "/:lang/types",
        type: "page",
        link: "/types",
        name: "types",
        container: AppPublic,
        comp: Types,
        public_menu: true
    },
    {
        path: "/:lang/news",
        type: "page",
        link: "/news",
        name: "news",
        container: AppPublic,
        comp: News,
        public_menu: true
    },
    {
        path: "/:lang/news/:id",
        type: "page",
        link: "/news/:id",
        name: "news",
        container: AppPublic,
        comp: NewsItem
    },
    {
        path: "/:lang/article/:id",
        type: "page",
        link: "/article/:id",
        name: "article",
        container: AppPublic,
        comp: ArticleItem,
    },
    {
        path: "/:lang/price",
        type: "page",
        link: "/price",
        name: "price",
        container: AppPublic,
        comp: Price,
        public_menu: true

    },
    {
        path: "/:lang/about",
        type: "page",
        link: "/about",
        name: "about",
        container: AppPublic,
        comp: Home,
        public_menu: false

    },
    {
        path: "/:lang/terms",
        type: "page",
        link: "/terms",
        name: "terms",
        container: AppPublic,
        comp: Terms,
    },
    {
        path: "/:lang/faq",
        type: "page",
        link: "/faq",
        name: "faq",
        container: AppPublic,
        comp: Faq,
    },
    {
        path: "/:lang/start",
        type: "page",
        link: "/start",
        name: "start",
        container: AppPublic,
        comp: Start,
        public_menu: true

    },
    {
        path: "/:lang/contact",
        type: "page",
        link: "/contact",
        name: "contact",
        container: AppPublic,
        comp: Contact,
        public_menu: true
    },
    {
        path: "/:lang/privacy",
        type: "page",
        link: "/privacy",
        name: "privacy",
        container: AppPublic,
        comp: Privacy,
        public_menu: false
    },
    {
        path: "/:lang/logo",
        type: "page",
        link: "/logo",
        name: "logo",
        container: AppPublic,
        comp: Logo,
        public_menu: false
    },
    //404
    {path: "/", to: "/home", redirect: true},

];

export default appRoutes
