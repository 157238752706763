import React, {Component} from 'react';
import {withRouter} from "react-router";
import {translate} from 'react-i18next';

import '../../css/price.scss';

class Home extends Component {

    render() {
        const {t} = this.props;
        return (
            <div className={'center1500'}>
                <div className={'page-content padding-public'}>
                    <center>
                        <div className={'main-title'}>
                            <h1>{t('title1')}</h1><br/>
                            {t('title2')}<br/>
                        </div>
                        <br/><br/>
                        <div className="price-container">
                            <div className={'item'}>
                                <h3>{t('visa1.title')}</h3>
                                {t('visa1.text')}
                                <div className={'price'}>{t('visa1.price')}</div>

                            </div>
                            <div className={'item'}>
                                <h3>{t('visa2.title')}</h3>
                                {t('visa2.text')}
                                <div className={'price'}>{t('visa2.price')}</div>
                            </div>

                            <div className={'item'}>
                                <h3>{t('visa3.title')}</h3>
                                <div className={'price'}>{t('visa3.price')}</div>
                            </div>
                            <div className={'item'}>
                                <h3>{t('visa4.title')}</h3>
                                <div className={'price'}>{t('visa4.price')}</div>
                            </div>

                            <div className={'item'}>
                                <h3>{t('visa5.title')}</h3>
                                {t('visa5.text')}
                                <div className={'price'}>{t('visa5.price')}</div>
                            </div>

                            <div className={'item'}>
                                <h3>{t('visa5.title')}</h3>
                                {t('visa5.text')}
                                <div className={'price'}>{t('visa5.price')}</div>
                            </div>

                            <div className={'item'}>
                                <h3>{t('visa7.title')}</h3>
                                <div className={'price'}>{t('visa7.price')}</div>
                            </div>
                        </div>
                        <br/><br/>
                    </center>
                </div>
            </div>
        )
    }
}

export default withRouter(translate('price')(Home));
