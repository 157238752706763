import React, {Component} from 'react';
import {withRouter} from "react-router";
import {translate} from 'react-i18next';

import '../../css/start.scss';

class Logo extends Component {

    render() {
        return (
            <div className={'center1500'}>
                <div className={'page-content padding-public'}>
                    <center>
                        <div className={'biglogo'}/>
                    </center>
                </div>
            </div>
        )
    }
}

export default withRouter(translate('start')(Logo));
