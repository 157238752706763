import React, {Component} from 'react';
import {translate} from 'react-i18next';
import {withRouter} from 'react-router'
import {NavLink} from "react-router-dom";

class Footer extends Component {
  render() {

    const {
      t, i18n
    } = this.props;

    return (
      <footer className="footer">
        <div className={"foot1"}>
          <div className={'center'}>
            <div className={'max-width'}>
              <div className={'footer-flex-row width'}>
                <div className={'footer-menu'}>
                  <div className={'footer-title'}>{t('title-menu')}</div>
                  <div>
                    <NavLink key={"home"} to={"/" + i18n.language + "/"}>
                      {t('menu.home')}
                    </NavLink>
                  </div>
                  <div>
                    <NavLink key={"about"} to={"/" + i18n.language + "/about"}>
                      {t('menu.about')}
                    </NavLink>
                  </div>
                  <div>
                    <NavLink key={"news"} to={"/" + i18n.language + "/news"}>
                      {t('menu.news')}
                    </NavLink>
                  </div>
                  <div>
                    <NavLink key={"contact"} to={"/" + i18n.language + "/contact"}>
                      {t('menu.contact')}
                    </NavLink>
                  </div>
                </div>
                <div className={'footer-menu'}>
                  <div className={'footer-title'}>{t('title-clients')}</div>
                  <div>
                    <NavLink key={"start"} to={"/" + i18n.language + "/start"}>
                      {t('menu.new')}
                    </NavLink>
                  </div>
                  <div>
                    <NavLink key={"price"} to={"/" + i18n.language + "/price"}>
                      {t('menu.price')}
                    </NavLink>
                  </div>
                  <div>
                    <NavLink key={"terms"} to={"/" + i18n.language + "/terms"}>
                      {t('menu.terms')}
                    </NavLink>
                  </div>
                  <div>
                    <NavLink key={"faq"} to={"/" + i18n.language + "/faq"}>
                      {t('menu.faq')}
                    </NavLink>
                  </div>
                  <div>
                    <NavLink key={"privacy"} to={"/" + i18n.language + "/privacy"}>
                      {t('menu.privacy')}
                    </NavLink>
                  </div>
                </div>
                <div className={'footer-menu'}>
                  <Contact2 {...this.props}/>
                </div>
                <div className={'footer-menu nomargin'}>
                  <Contact {...this.props}/>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={"foot2"}>
          <div className={'footer-center'}>
            <img alt={"v.1.0.5"} className={'footer-logo'} src={'/img/logo-royal.svg'}/>
            <br/>
            <a href={"https://t.me/royalvisa"} target={"_new"}><img src={"/img/teleg.svg"} alt={"telegram"} className={'ico'}/></a>
            <a href={"mailto:info@royalvisa.ru"} target={"_new"}><img src={"/img/email.svg"} alt={""} className={'ico'}/></a>
            <img src={"/img/twitter.svg"} alt={""} className={'ico'}/>
            <img src={"/img/instagram.svg"} alt={""} className={'ico'}/>

            <br/><br/>
            <font className={'copyright'}>{t('copyright')} v 1.0.5</font>
          </div>
        </div>

      </footer>
    );
  }
}

export default withRouter(translate(['footer', 'contact'])(Footer));

class Contact extends Component {
  render() {

    const {
      t,
    } = this.props;

    return (
      <div className={'left'}>
        <div className={'footer-title b2'}>{t('title-contact')}</div>
        <div className={'div-contact-footer'}>
          <div>{t('contact.phone')}<br/><font>{t('contact:call_data')}</font></div>
          <div>{t('contact.email')}<br/><font>{t('contact:email_data')}</font></div>
          <div>{t('contact.telegram')}<br/><font><a href={"https://t.me/royalvisa"} target={"_new"} className={'telegram'}>{t('contact:message_data')}</a></font></div>
        </div>
      </div>
    );
  }
}


class Contact2 extends Component {
  render() {

    const {
      t,
    } = this.props;

    return (
      <div className={'left'}>
        <div className={'footer-title b2'}>{t('title-mode')}</div>
        <div className={'div-contact-footer'}>
          <div dangerouslySetInnerHTML={{__html: t('contact.mode1')}}/>
          <div dangerouslySetInnerHTML={{__html: t('contact.mode2')}}/>
          <div dangerouslySetInnerHTML={{__html: t('contact.mode-online')}}/>
        </div>
      </div>
    );
  }
}
