import React, {Component} from 'react';
import {withRouter} from "react-router";
import {translate} from 'react-i18next';

import '../../css/terms.scss';

class Terms extends Component {
    press_news = (text) => {
        if (text === "undefined") {
            return
        }
        text = text.replace(/\\</gi, '');
        text = text.replace(/\\>/gi, '');
        text = text.replace(
            /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi,
            '<a target="_new" class="url" href="$1">$1</a>'
        );
        text = text.replace(/\n/gi, '<br/>');
        return text;
    };

    render() {
        const {t} = this.props;
        return (
            <div className={'center1500'}>
                <div className={'page-content padding-public'}>
                    <center>
                        <div className={'main-title'}>

                            <h1>{t('title1')}</h1>
                        </div>

                        <br/>

                        <div className={'terms-cointainer'}>
                            <div className={'terms-box'}>
                                <h2 className={'title'}>{t('block1.title')}</h2>
                                <div className={'text'}
                                     dangerouslySetInnerHTML={{__html: this.press_news(t('block1.text'))}}/>
                            </div>
                            <div className={'terms-box'}>
                                <h2 className={'title'}>{t('block2.title')}</h2>
                                <div className={'text'}
                                     dangerouslySetInnerHTML={{__html: this.press_news(t('block2.text'))}}/>
                            </div>
                        </div>

                        <div className={'terms-cointainer'}>
                            <div className={'terms-box'}>
                                <h2 className={'title'}>{t('block3.title')}</h2>
                                <div className={'text'}
                                     dangerouslySetInnerHTML={{__html: this.press_news(t('block3.text'))}}/>
                            </div>
                            <div className={'terms-box'}>
                                <h2 className={'title'}>{t('block4.title')}</h2>
                                <div className={'text'}
                                     dangerouslySetInnerHTML={{__html: this.press_news(t('block4.text'))}}/>
                            </div>
                        </div>

                        <div className={'terms-cointainer'}>
                            <div className={'terms-box'}>
                                <h2 className={'title'}>{t('block5.title')}</h2>
                                <div className={'text'}
                                     dangerouslySetInnerHTML={{__html: this.press_news(t('block5.text'))}}/>
                            </div>
                            <div className={'terms-box'}>
                                <h2 className={'title'}>{t('block6.title')}</h2>
                                <div className={'text'}
                                     dangerouslySetInnerHTML={{__html: this.press_news(t('block6.text'))}}/>
                            </div>
                        </div>

                        <div className={'terms-cointainer'}>
                            <div className={'terms-box'}>
                                <h2 className={'title'}>{t('block7.title')}</h2>
                                <div className={'text'}
                                     dangerouslySetInnerHTML={{__html: this.press_news(t('block7.text'))}}/>
                            </div>
                            <div className={'terms-box'}>
                                <h2 className={'title'}>{t('block8.title')}</h2>
                                <div className={'text'}
                                     dangerouslySetInnerHTML={{__html: this.press_news(t('block8.text'))}}/>
                            </div>
                        </div>

                    </center>

                </div>
            </div>
        )
    }
}

export default withRouter(translate('terms')(Terms));
